import { defineComponent } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import CheckIcon from '@/assets/icons/domains/subscriptions-list/CheckIcon.vue';
import CloseIcon from '@/assets/icons/domains/finance-admin/CloseIcon.vue';
import DeleteIcon from '@/assets/icons/domains/themes/DeleteIcon.vue';
import SendIcon from '@/assets/icons/domains/pd-admin/SendIcon.vue';
import UpdateIcon from '@/assets/icons/domains/themes/UpdateIcon.vue';
import FilterIcon from '@/assets/icons/domains/finance-admin/FilterIcon.vue';
import QuestionIcon from '@/assets/icons/domains/themes/QuestionIcon.vue';
import RefreshIcon from '@/assets/icons/domains/themes/RefreshIcon.vue';
import ExclamationIcon from '@/assets/icons/domains/team-members/ExclamationIcon.vue';
import {
  ZidIcon,
  ZidInput,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidModal,
  ZidLoader,
  ZidButton,
  ZidDatepicker,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
  ZidPageHeader,
  ZidPagination,
  ZidCard,
  ZidCardHeader,
  ZidCardBody,
  ZidCheckbox,
  ZidTooltip,
} from '@zidsa/ui';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import EditIcon from '@/assets/icons/domains/themes/EditIcon.vue';
import RightPointingArrowIcon from '@/assets/icons/domains/team-members/RightPointingArrowIcon.vue';
import useThemesManagement from '../../helpers/management/useThemesManagement';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    CheckIcon,
    CloseIcon,
    ZidIcon,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidDatepicker,
    SearchIcon,
    EditIcon,
    DeleteIcon,
    SendIcon,
    UpdateIcon,
    ZidModal,
    ZidLoader,
    ZidButton,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
    ZidPageHeader,
    RightPointingArrowIcon,
    ZidPagination,
    ZidCard,
    ZidCardHeader,
    ZidCardBody,
    ZidCheckbox,
    FilterIcon,
    QuestionIcon,
    RefreshIcon,
    ZidTooltip,
    ExclamationIcon,
  },
  setup() {
    return {
      ...useThemesManagement(),
    };
  },
});
