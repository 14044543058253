import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import AllThemesResponseModel from '@/domains/themes/models/management/AllThemesResponseModel';
import DeleteThemeResponseModel from '@/domains/themes/models/management/DeleteThemeResponseModel';
import {
  ThemesFilterParamsType,
  ThemesFilterParamsInterface,
} from '@/domains/themes/types/management/themesManagement.type';
import CreateThemeResponseModel from '../../models/management/CreateThemeResponseModel';

const ENDPOINT_URL = '/pd/themes';

export const getThemesManagementList = (
  filters: ThemesFilterParamsType,
): Promise<AllThemesResponseModel | ErrorModel> => {
  const filterParams: ThemesFilterParamsInterface = {};

  if (filters.term && !filters.status.length) {
    filterParams.searchTerm = filters.term;
  }
  if (filters.status.length) {
    filterParams.status = [...filters.status];
  }
  if (filters.resultPerPage) {
    filterParams.pageSize = filters.resultPerPage;
  }
  if (filters.targetPage) {
    filterParams.page = filters.targetPage;
  }

  return httpClient
    .get(`${ENDPOINT_URL}`, {
      params: filterParams,
    })
    .then((response) => new AllThemesResponseModel(response))
    .catch((error) => error);
};

export const createTheme = (nameAr: string, nameEn: string): Promise<CreateThemeResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/new`, {
      name_ar: nameAr,
      name_en: nameEn,
    })
    .then((response) => new CreateThemeResponseModel(response))
    .catch((error) => error);
};

export const deleteTheme = (themeId: string): Promise<DeleteThemeResponseModel | ErrorModel> => {
  return httpClient
    .delete(`${ENDPOINT_URL}/${themeId}`)
    .then((response) => new DeleteThemeResponseModel(response))
    .catch((error) => error);
};
