import BaseModel from '@/api/models/BaseModel';

class DeleteThemeResponseModel extends BaseModel {
  public status: string;

  constructor(arg: any) {
    super(arg);
    this.status = arg.status;
  }
}

export default DeleteThemeResponseModel;
