import { AdditionalFeature } from './theme-info-container/themeInfoContainer';

export enum ThemeUpdateTypeLabelsEnum {
  Minor = 'Minor',
  Major = 'Major',
  Content = 'Content',
}

export enum ThemeUpdateTypeValuesEnum {
  Minor = 'minor',
  Major = 'major',
  Content = 'content',
}

export enum ReviewStatusLabelsEnum {
  All = 'All Statuses',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Accepted = 'Accepted',
  Draft = 'Draft',
}

export enum ReviewStatusValuesEnum {
  All = '',
  Rejected = '0',
  Pending = '1',
  Accepted = '2',
  Draft = '3',
}

export type ManageThemeListState = {
  searchValue: string | number;
  selectedResultPerPage: Record<string, string>;
  status: Record<string, string>;
  targetPage: number;
};

export interface CreateNewThemeStateInterface {
  themeNameEn: string;
  themeNameAr: string;
}

export type ManageThemeListFilterState = {
  searchValue: string | number;
  themeStatus: Record<string, string>;
  dateRange: Record<string, string>;
};

export enum ManageThemesSelectProperty {
  ResultPerPage = 'selectedResultPerPage',
  Status = 'status',
}

export enum ThemeActionsEnum {
  Edit = 'edit',
  Delete = 'delete',
  Update = 'update',
  Submit = 'submit',
}

export enum ManageThemesSearchProperty {
  SearchValue = 'searchValue',
}

export enum CreateThemeInputPropertiesEnum {
  ThemeNameEn = 'themeNameEn',
  ThemeNameAr = 'themeNameAr',
}

export enum ManageThemesDateRangeProperty {
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export enum ManageThemeListThemeStatusProperty {
  ThemeStatus = 'ThemeStatus',
}

export type SingleThemeDetail = {
  theme_id: string;
  theme_name: string;
  theme_name_ar: string;
  price: string;
  discounted_price: string;
  category: number[];
  preview_url: string;
  demo_url: string;
  description: string;
  description_ar: string;
  features: number[];
  additional_features: AdditionalFeature[];
  technical_support_email: string;
  website_url: string;
  images: string;
  featured_image: string;
  file_path: string;
  has_signed_partnership_document: boolean;
  status: ItemStatusEnum;
  update_status: ItemUpdateStatusEnum;
  version: string;
  published_date: string;
  changes_notes_en: string;
  changes_notes_ar: string;
};

export type ThemeItemType = {
  id: string;
  name: string;
  created_at: string;
  version: string;
  price: null | string;
  publishing_date: null | string;
  status: ItemStatusEnum;
  update_status: ItemUpdateStatusEnum;
};

export enum ItemStatusEnum {
  Rejected = 0,
  Pending = 1,
  Accepted = 2,
  Draft = 3,
}

export enum ItemUpdateStatusEnum {
  Non = 0,
  InReview = 1,
  Accepted = 2,
  Rejected = 3,
}

export type ThemesListType = {
  list: ThemeItemType[];
};

export type SortingStateType = {
  price: null | boolean;
  created_at: null | boolean;
  publishing_date: null | boolean;
};

export enum SortingStateProperties {
  Price = 'price',
  Created_date = 'created_at',
  Publishing_date = 'publishing_date',
}

export type ThemesFilterParamsType = {
  term: string | number;
  status: number[];
  resultPerPage: string | number;
  targetPage: number;
};

export interface ThemesFilterParamsInterface {
  searchTerm?: string | number;
  status?: number[];
  pageSize?: string | number;
  page?: number;
}

export enum PublishingStatusLabelEnum {
  Published = 'Published',
  InReview = 'In Review',
  Rejected = 'Rejected',
  Draft = 'Draft',
}

export enum PublishingStatusValuesEnum {
  Rejected = 0,
  InReview = 1,
  Published = 2,
  Draft = 3,
}

export enum ThemeFlowEnum {
  IsCreateThemeFlow = '1',
  IsEditThemeFlow = '2',
}
