import Vue from 'vue';
import { reactive, ref, onBeforeMount, watch } from '@vue/composition-api';
import router from '@/router/index';
import { RoutingRouteEnum } from '@/router/routes.enum';
import {
  ThemesListType,
  SortingStateType,
  ThemeActionsEnum,
  ManageThemeListState,
  ReviewStatusLabelsEnum,
  ReviewStatusValuesEnum,
  SortingStateProperties,
  ManageThemesSearchProperty,
  PublishingStatusValuesEnum,
  PublishingStatusLabelEnum,
  CreateNewThemeStateInterface,
  CreateThemeInputPropertiesEnum,
  ThemeFlowEnum,
  ItemStatusEnum,
  ItemUpdateStatusEnum,
} from '../../types/management/themesManagement.type';
import useVuelidate from '@vuelidate/core';
import ErrorModel from '@/api/models/ErrorModel';
import { required } from '@vuelidate/validators';
import { createTheme, deleteTheme, getThemesManagementList } from '@/domains/themes/api/management/management';
import { InputKeyUpKeysEnum } from '@/types/components/input/inputTypes';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ARABIC_TEXT, ENGLISH_TEXT } from '@/helpers/regex/text';
dayjs.extend(customParseFormat);

const useThemesManagement = () => {
  const DEFAULT_ITEM_PER_PAGE = 10;
  const RESULT_PER_PAGE = [];

  const showFiltersBox = ref(false);
  const loadingThemeList = ref(false);
  const isDeletingTheme = ref(false);
  const isCreatingTheme = ref(false);
  const showAddThemeModal = ref(false);
  const showDeleteThemeModal = ref(false);
  const selectedThemeId = ref('');
  const selectedThemeName = ref('');
  const totalPages = ref(0);
  const totalResults = ref(0);

  const originalThemeList = reactive<ThemesListType>({
    list: [],
  });
  const selectedStatuses = reactive<Record<string, PublishingStatusValuesEnum[]>>({ list: [] });

  for (let i = DEFAULT_ITEM_PER_PAGE; i <= 30; i += DEFAULT_ITEM_PER_PAGE) {
    RESULT_PER_PAGE.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  const resultPerPage = reactive({
    options: RESULT_PER_PAGE,
  });

  const sortingStates = reactive<SortingStateType>({
    price: null,
    created_at: null,
    publishing_date: null,
  });

  const ManageThemeListState = reactive<ManageThemeListState>({
    searchValue: '',
    selectedResultPerPage: {
      label: `${DEFAULT_ITEM_PER_PAGE}`,
      value: `${DEFAULT_ITEM_PER_PAGE}`,
    },
    status: {
      label: '',
      value: '',
    },
    targetPage: 1,
  });

  const rules = {
    searchValue: {},
    selectedResultPerPage: {
      label: {},
      value: {},
    },
    status: {
      label: {},
      value: {},
    },
    targetPage: {},
  };

  const createNewThemeState = reactive<CreateNewThemeStateInterface>({
    themeNameEn: '',
    themeNameAr: '',
  });

  const createThemeStateRules = {
    themeNameEn: {
      required,
      themeNameEn: (value: string) => {
        return ENGLISH_TEXT.test(value);
      },
    },
    themeNameAr: {
      required,
      themeNameAr: (value: string) => {
        return ARABIC_TEXT.test(value);
      },
    },
  };

  const v$ = useVuelidate(rules, ManageThemeListState);
  const v2$ = useVuelidate(createThemeStateRules, createNewThemeState);

  onBeforeMount(async () => {
    await getAllThemes();
  });

  const getAllThemes = async () => {
    loadingThemeList.value = true;
    originalThemeList.list = [];
    const parameters = {
      term: ManageThemeListState.searchValue,
      status: selectedStatuses.list,
      resultPerPage: ManageThemeListState.selectedResultPerPage.value,
      targetPage: ManageThemeListState.targetPage,
    };
    const response = await getThemesManagementList(parameters);
    if (response instanceof ErrorModel || !response.items) {
      Vue.$toast.error('Unable to get themes list');
      loadingThemeList.value = false;
      return;
    }
    originalThemeList.list = [...response.items];
    totalPages.value = response.pagination.last_page;
    totalResults.value = response.pagination.total;
    loadingThemeList.value = false;
  };

  const onInputDataChange = (keyUp: KeyboardEvent, dataProperty: ManageThemesSearchProperty) => {
    const target = keyUp.target as HTMLTextAreaElement;
    if (keyUp.key === InputKeyUpKeysEnum.Enter) {
      v$.value[dataProperty].$touch();
      ManageThemeListState[dataProperty] = target.value;
    }
  };

  const onCreateThemeInputsChange = (
    changedValue: Record<string, string>,
    dataProperty: CreateThemeInputPropertiesEnum,
  ) => {
    v2$.value[dataProperty].$touch();
    createNewThemeState[dataProperty] = changedValue.value;
  };

  const onStatusChange = (isChecked: boolean, checkedStatus: PublishingStatusValuesEnum) => {
    if (!isChecked) {
      selectedStatuses.list = selectedStatuses.list.filter((status) => status !== checkedStatus);
      return;
    }
    if (isSelectedStatus(checkedStatus)) {
      return;
    }
    selectedStatuses.list.push(checkedStatus);
  };

  const onFilterResultsClick = () => {
    showFiltersBox.value = true;
  };

  const onSelectAllStatuses = (isChecked: boolean) => {
    if (!isChecked) {
      selectedStatuses.list = [];
      return;
    }
    selectedStatuses.list = [
      PublishingStatusValuesEnum.Published,
      PublishingStatusValuesEnum.InReview,
      PublishingStatusValuesEnum.Rejected,
      PublishingStatusValuesEnum.Draft,
    ];
  };

  const onApplyFiltersClick = async () => {
    await getAllThemes();
    resetTargetPage();
    showFiltersBox.value = false;
  };

  const onCancelFiltersButtonClick = () => {
    showFiltersBox.value = false;
  };

  const toggleAddThemeModal = () => {
    createNewThemeState.themeNameAr = '';
    createNewThemeState.themeNameEn = '';
    v2$.value.$reset();
    showAddThemeModal.value = !showAddThemeModal.value;
  };

  const toggleDeleteThemeModal = (themeId?: string, themeName?: string) => {
    selectedThemeId.value = themeId ?? '';
    selectedThemeName.value = themeName ?? '';
    showDeleteThemeModal.value = !showDeleteThemeModal.value;
  };

  const onThemeDelete = async (themeId: string) => {
    isDeletingTheme.value = true;
    const response = await deleteTheme(themeId);
    if (response instanceof ErrorModel || !response.status) {
      Vue.$toast.error('Unable to delete the theme');
      isDeletingTheme.value = false;
      return;
    }
    isDeletingTheme.value = false;
    showDeleteThemeModal.value = false;
    Vue.$toast.success('The theme has been deleted');
    await getAllThemes();
  };

  const isSelectedStatus = (status: PublishingStatusValuesEnum) => {
    return selectedStatuses.list.some((selectedStatus) => selectedStatus === status);
  };

  const onEditClicked = async (themeId: string, status: ItemStatusEnum) => {
    let flowType = ThemeFlowEnum.IsCreateThemeFlow;
    if (status == ItemStatusEnum.Accepted) flowType = ThemeFlowEnum.IsEditThemeFlow;
    router
      .push({
        name: RoutingRouteEnum.ThemeInfoContainer,
        params: {
          themeId: themeId,
          flowType,
        },
      })
      .catch(() => {
        //
      });
  };

  const onCreateNewThemeClicked = async () => {
    isCreatingTheme.value = true;

    const response = await createTheme(createNewThemeState.themeNameAr, createNewThemeState.themeNameEn);

    if (response instanceof ErrorModel || !response.payload) {
      if (response instanceof ErrorModel && response.validations.length && response.validations[0].errors.length)
        Vue.$toast.error(response.validations[0].errors[0]);
      else Vue.$toast.error('Could not create theme');
      isCreatingTheme.value = false;
      showAddThemeModal.value = false;
      return;
    }

    Vue.$toast.success('New theme record has been created');
    isCreatingTheme.value = false;
    showAddThemeModal.value = false;

    router
      .push({
        name: RoutingRouteEnum.ThemeInfoContainer,
        params: {
          themeId: response.payload.theme_id,
          flowType: ThemeFlowEnum.IsCreateThemeFlow,
        },
      })
      .catch(() => {
        //
      });
  };

  const sortingHandlersState = (sortingProperty: SortingStateProperties) => {
    const property = sortingStates[sortingProperty];
    if (property) {
      return 'asc';
    } else {
      return 'desc';
    }
  };

  const onSort = (sortingProperty: SortingStateProperties) => {
    const currentSortingState = sortingStates[sortingProperty];
    const sortDirection = currentSortingState ? -1 : 1; // Toggle the sort direction

    switch (sortingProperty) {
      case SortingStateProperties.Created_date:
      case SortingStateProperties.Publishing_date: {
        const recordsWithDate = originalThemeList.list.filter((theme) => theme[sortingProperty] !== null);
        const recordsWithEmptyDate = originalThemeList.list.filter((theme) => theme[sortingProperty] === null);

        originalThemeList.list = [
          ...recordsWithDate.sort((a, b) => {
            const leftDate = dayjs(a[sortingProperty], 'YYYY-MM-DD');
            const rightDate = dayjs(b[sortingProperty], 'YYYY-MM-DD');
            return sortDirection * leftDate.diff(rightDate);
          }),
          ...recordsWithEmptyDate,
        ];
        break;
      }
      case SortingStateProperties.Price: {
        const recordsWithPrice = originalThemeList.list.filter((theme) => theme.price !== null);
        const recordsWithEmptyPrice = originalThemeList.list.filter((theme) => theme.price === null);

        originalThemeList.list = [
          ...recordsWithPrice.sort((a, b) => sortDirection * (parseFloat(a.price || '0') - parseFloat(b.price || '0'))),
          ...recordsWithEmptyPrice,
        ];
        break;
      }
      default:
        break;
    }

    sortingStates[sortingProperty] = !currentSortingState; // Toggle the sorting state
  };

  const onCreateThemeBtnClicked = () => {
    toggleAddThemeModal();
  };

  const resetTargetPage = () => (ManageThemeListState.targetPage = 1);

  watch(
    ManageThemeListState,
    async () => {
      await getAllThemes();
    },
    { deep: true },
  );

  const onResultPerPageChange = (perPage: Record<string, string>) => {
    ManageThemeListState.selectedResultPerPage = perPage;
    resetTargetPage();
  };

  return {
    v$,
    v2$,
    loadingThemeList,
    originalThemeList,
    ManageThemeListState,
    createNewThemeState,
    resultPerPage,
    sortingStates,
    showFiltersBox,
    ThemeActionsEnum,
    SortingStateProperties,
    ReviewStatusLabelsEnum,
    ReviewStatusValuesEnum,
    PublishingStatusValuesEnum,
    PublishingStatusLabelEnum,
    CreateThemeInputPropertiesEnum,
    ManageThemesSearchProperty,
    totalPages,
    totalResults,
    selectedThemeId,
    selectedThemeName,
    showAddThemeModal,
    showDeleteThemeModal,
    isCreatingTheme,
    isDeletingTheme,
    selectedStatuses,
    ItemStatusEnum,
    ItemUpdateStatusEnum,
    isSelectedStatus,
    onSort,
    onStatusChange,
    onSelectAllStatuses,
    onEditClicked,
    onInputDataChange,
    sortingHandlersState,
    onCreateThemeBtnClicked,
    toggleDeleteThemeModal,
    toggleAddThemeModal,
    onResultPerPageChange,
    onCancelFiltersButtonClick,
    onCreateThemeInputsChange,
    onApplyFiltersClick,
    onFilterResultsClick,
    onCreateNewThemeClicked,
    onThemeDelete,
  };
};

export default useThemesManagement;
