import BaseModel from '@/api/models/BaseModel';
import { SingleThemeDetail } from '../../types/management/themesManagement.type';

class CreateThemeResponseModel extends BaseModel {
  public payload: SingleThemeDetail;

  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default CreateThemeResponseModel;
