import BaseModel from '@/api/models/BaseModel';
import { LengthAwarePaginationInterface } from '@/types/global-types/pagination.type';
import { ThemeItemType } from '../../types/management/themesManagement.type';

class AllThemesResponseModel extends BaseModel {
  public items: ThemeItemType[];
  public pagination: LengthAwarePaginationInterface;

  constructor(arg: any) {
    super(arg);
    this.items = arg.items;
    this.pagination = arg.pagination;
  }
}

export default AllThemesResponseModel;
