//eslint-disable-next-line
export const ENGLISH_TEXT_WITH_NEW_LINE: RegExp = /^[A-Za-z0-9 _]*[A-Za-z]+[A-Za-z0-9 _|\n]*$/;

//eslint-disable-next-line
export const ENGLISH_TEXT: RegExp = /^[A-Za-z0-9 _]*[A-Za-z]+[A-Za-z0-9 _]*$/;

//eslint-disable-next-line
export const ARABIC_TEXT_WITH_NEW_LINE: RegExp = /^[\u0621-\u064A0-9 |\n ]+$/;

//eslint-disable-next-line
export const ARABIC_TEXT: RegExp = /^[\u0621-\u064A0-9 ]+$/;

//eslint-disable-next-line
export const ENGLISH_TEXT_WITH_SPECIAL_CHARS: RegExp = /^[A-Za-z0-9 _().,؛:-]*[A-Za-z]+[A-Za-z0-9 _().,؛:-]*$/;

//eslint-disable-next-line
export const ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS: RegExp = /^[A-Za-z0-9 _().,؛:-]*[A-Za-z]+[A-Za-z0-9 _\-().,؛:|\n\s]*$/;

//eslint-disable-next-line
export const ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS: RegExp = /^[\u0621-\u064A0-9 _\-().,،.؛:|\n\s]+$/;

//eslint-disable-next-line
export const ARABIC_TEXT_WITH_SPECIAL_CHARS: RegExp = /^[\u0621-\u064A0-9 _()..،,؛:-]+$/;
